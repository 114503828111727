import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

import '../../generic-page.scss';

const NikkeGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>NIKKE Guides</h1>
          <h2>Guides for Goddess of Victory: NIKKE gacha game.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/nikke/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_guide.png"
                alt="Introduction to the game"
              />
            }
          />
          <CategoryCard
            title="Reroll guide"
            link="/nikke/guides/reroll"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_reroll.png"
                alt="Reroll guide"
              />
            }
            updated
          />
          <CategoryCard
            title="Wishlist tips"
            link="/nikke/guides/wishlist"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_shops.png"
                alt="Wishlist"
              />
            }
            updated
          />
          <CategoryCard
            title="Meta teams (Story)"
            link="/nikke/guides/meta-teams"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_beginner.png"
                alt="Early and late meta teams"
              />
            }
          />
          <CategoryCard
            title="Meta teams (Towers)"
            link="/nikke/guides/tribe-tower-teams"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tribe_tower_teams.webp"
                alt="Tribe Tower"
              />
            }
          />
        </Row>
        <SectionHeader title="Character Power Systems" />
        <h5>Collection Items</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Collection Items - Intro"
            link="/nikke/guides/collection-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_coll.jpg"
                alt="Collection Items - Intro"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Upgrade Optimization"
            link="/nikke/guides/collection-dolls"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dolls.jpg"
                alt="Collection Items - Upgrade Optimization"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Dispatch"
            link="/nikke/guides/collection-dispatch"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dispatch.jpg"
                alt="Collection Items - Dispatch"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Priority"
            link="/nikke/guides/collection-priority"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_priority.jpg"
                alt="Collection Items - Priority"
              />
            }
          />
        </Row>
        <h5>Overload Gear</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="OL Gear - Intro"
            link="/nikke/guides/overload-gear-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ol.webp"
                alt="Overload Gear - Intro"
              />
            }
          />
          <CategoryCard
            title="OL Gear - Recommendations"
            link="/nikke/guides/overload-gear-recommendations"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_olrec.jpg"
                alt="OL Gear - Recommendations "
              />
            }
          />
          <CategoryCard
            title="OL Gear - Rerolling"
            link="/nikke/guides/overload-gear-reroll"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_olreroll.jpg"
                alt="OL Gear - Rerolling "
              />
            }
          />
        </Row>
        <h5>Harmony Cubes</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Harmony Cubes - information"
            link="/nikke/guides/harmony-cubes-information"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_cubes.png"
                alt="Harmony Cubes - information"
              />
            }
          />
          <CategoryCard
            title="Harmony Cubes - data"
            link="/nikke/guides/harmony-cubes-data"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_cubedata.png"
                alt="Harmony Cubes - data"
              />
            }
          />
          <CategoryCard
            title="Harmony Cubes - suggestions"
            link="/nikke/guides/harmony-cubes-suggestions"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_cubestips.png"
                alt="Harmony Cubes - suggestions"
              />
            }
          />
        </Row>
        <h5>Skills</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Skill investment Guide"
            link="/nikke/guides/skill-investment"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_skills.jpg"
                alt="Skill investment Guide"
              />
            }
          />
        </Row>
        <SectionHeader title="PVP Zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Intro to PVP"
            link="/nikke/guides/pvp-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp.jpg"
                alt="Intro to PVP"
              />
            }
          />
          <CategoryCard
            title="PvP Arena Mechanics"
            link="/nikke/guides/pvp-mechanics"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp2.jpg"
                alt="PvP Arena Mechanics"
              />
            }
          />
          <CategoryCard
            title="PvP Burst Generation"
            link="/nikke/guides/pvp-burst"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp3.jpg"
                alt="PvP Burst Generation"
              />
            }
          />
          <CategoryCard
            title="Basic Team-building & Meta"
            link="/nikke/guides/pvp-sp-arena"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp4.jpg"
                alt="PvP Burst Generation"
              />
            }
          />
          <CategoryCard
            title="Meta Teams and Archetypes"
            link="/nikke/guides/pvp-sp-arena-teams"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp5.jpg"
                alt="Meta Teams and Archetypes"
              />
            }
          />
        </Row>
        <SectionHeader title="Data" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Bond ranks"
            link="/nikke/guides/bond-ranks"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_bond.png"
                alt="Bond Ranks"
              />
            }
          />
          <CategoryCard
            title="Level requirements"
            link="/nikke/guides/level-requirements"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_level.png"
                alt="Level requirements"
              />
            }
          />
          <CategoryCard
            title="Outpost output"
            link="/nikke/guides/outpost-output"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_output.png"
                alt="Outpost output"
              />
            }
          />
          <CategoryCard
            title="Stage penalty"
            link="/nikke/guides/stage-penalty"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_penalty.png"
                alt="Stage penalty"
              />
            }
          />
          <CategoryCard
            title="Union levels"
            link="/nikke/guides/union-levels"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_unionlevels.png"
                alt="Union levels"
              />
            }
          />
        </Row>
        <SectionHeader title="General guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Character progression"
            link="/nikke/guides/character-progression"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_character.png"
                alt="Character progression"
              />
            }
          />
          <CategoryCard
            title="Lost Relics - overview"
            link="/nikke/guides/lost-relics"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_lostrelics.png"
                alt="Lost Relics"
              />
            }
          />
          <CategoryCard
            title="Interactive Map"
            link="/nikke/guides/map"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_lostrelics.png"
                alt="Interactive Map"
              />
            }
          />
          <CategoryCard
            title="Liberation"
            link="/nikke/guides/liberation"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_liberation.png"
                alt="Liberation"
              />
            }
          />
          <CategoryCard
            title="Team building"
            link="/nikke/guides/team-building"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_8.png"
                alt="Team building"
              />
            }
          />
          <CategoryCard
            title="Weapon types"
            link="/nikke/guides/weapon-types"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_7.png"
                alt="Weapon types"
              />
            }
          />
          <CategoryCard
            title="Spender guide"
            link="/nikke/guides/spender-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_spender.png"
                alt="Spender guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Anomaly Interception" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Anomaly Interception - Introduction"
            link="/nikke/guides/game-modes-anomaly-interception"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ai.webp"
                alt="Interception"
              />
            }
          />
          <CategoryCard
            title="Anomaly Interception- Kraken"
            link="/nikke/guides/ai-kraken"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ai_kraken.webp"
                alt="Interception Kraken"
              />
            }
          />
        </Row>
        <SectionHeader title="Union Raid" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Raid overview"
            link="/nikke/guides/union-raid"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_unionraid.png"
                alt="Union Raid"
              />
            }
          />
          <CategoryCard
            title="Solo Raid overview"
            link="https://blog.prydwen.gg/2023/05/10/nikke-solo-raid-information/"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_unionraid.png"
                alt="Union Raid"
              />
            }
            outsideLink
          />
        </Row>
        <SectionHeader title="Game modes" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Overview"
            link="/nikke/guides/game-modes"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_modes.png"
                alt="Game modes"
              />
            }
          />
          <CategoryCard
            title="Campaign"
            link="/nikke/guides/game-modes-campaign"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_story.png"
                alt="Campaign"
              />
            }
          />
          <CategoryCard
            title="Simulation Room"
            link="/nikke/guides/game-modes-simulation"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_sim.png"
                alt="Simulation Room"
              />
            }
          />
          <CategoryCard
            title="Interception"
            link="/nikke/guides/game-modes-interception"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_interception.png"
                alt="Interception"
              />
            }
          />
          <CategoryCard
            title="Anomaly Interception"
            link="/nikke/guides/game-modes-anomaly-interception"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ai.webp"
                alt="Interception"
              />
            }
          />
          <CategoryCard
            title="Tribe Towers"
            link="/nikke/guides/game-modes-tribe-towers"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tribe.png"
                alt="Tribe Towers"
              />
            }
          />
          <CategoryCard
            title="Lost Sector"
            link="/nikke/guides/game-modes-lost-sector"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_lost.png"
                alt="Lost Sector"
              />
            }
          />
        </Row>
        <SectionHeader title="Character guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Grave review"
            link="/nikke/guides/guide-grave"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_grave.webp"
                alt="Guide"
              />
            }
            isNew
          />
          <CategoryCard
            title="Cinderella review"
            link="/nikke/guides/guide-cinderella"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_cinderella.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Rumani review"
            link="/nikke/guides/guide-rumani"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_rumani.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Phantom review"
            link="/nikke/guides/guide-phantom"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_phantom.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Quency: Escape Queen review"
            link="/nikke/guides/guide-quency-escape-queen"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_quency_escape.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Rouge review"
            link="/nikke/guides/guide-rouge"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_rouge.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Mari Makinami Illustrious review"
            link="/nikke/guides/guide-mari-makinami"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_mari.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Rei Ayanami review"
            link="/nikke/guides/guide-rei-ayanami"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_rei.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Asuka Shikinami Langley review"
            link="/nikke/guides/guide-asuka"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_asuka.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Viper (Treasure) review"
            link="/nikke/guides/guide-viper-treasure"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_viper_tr.webp"
                alt="Viper (Treasure)"
              />
            }
          />
          <CategoryCard
            title="Frima (Treasure) review"
            link="/nikke/guides/guide-frima-treasure"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_frima_tr.webp"
                alt="Frima (Treasure)"
              />
            }
          />
          <CategoryCard
            title="Zwei review"
            link="/nikke/guides/guide-zwei"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_zwei.webp"
                alt="Zwei"
              />
            }
          />
          <CategoryCard
            title="Exia (Treasure) review"
            link="/nikke/guides/guide-exia-treasure"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_exia_tr.webp"
                alt="Exia (Treasure)"
              />
            }
          />
          <CategoryCard
            title="Ein review"
            link="/nikke/guides/guide-ein"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ein.webp"
                alt="Guide"
              />
            }
          />
          <CategoryCard
            title="Diesel (Treasure) review"
            link="/nikke/guides/guide-diesel-treasure"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_diesel_tr.webp"
                alt="Diesel (Treasure)"
              />
            }
          />
          <CategoryCard
            title="Rosanna Summer review"
            link="/nikke/guides/guide-rosanna-summer"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_rosanna_chic.webp"
                alt="Rosanna Summer"
              />
            }
          />
          <CategoryCard
            title="Sakura Summer review"
            link="/nikke/guides/guide-sakura-summer"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_sakurasummer.webp"
                alt="Sakura Summer"
              />
            }
          />
          <CategoryCard
            title="Laplace (Treasure) review"
            link="/nikke/guides/guide-laplace-treasure"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_laplace_tr.webp"
                alt="Laplace (Treasure)"
              />
            }
          />
          <CategoryCard
            title="Clay review"
            link="/nikke/guides/guide-clay"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_clay.webp"
                alt="Clay"
              />
            }
          />
          <CategoryCard
            title="Alice: Wonderland Bunny review"
            link="/nikke/guides/guide-alice-bunny"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_alicebunny.webp"
                alt="Alice: Wonderland Bunny"
              />
            }
          />
          <CategoryCard
            title="Soda: Twinkling Bunny review"
            link="/nikke/guides/guide-soda-bunny"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_sodabunny.webp"
                alt="Soda Bunny review"
              />
            }
          />
          <CategoryCard
            title="Trony review"
            link="/nikke/guides/guide-trony"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_trony.webp"
                alt="Trony review"
              />
            }
          />
          <CategoryCard
            title="Kilo review"
            link="/nikke/guides/guide-kilo"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_kilo.webp"
                alt="Kilo review"
              />
            }
          />
          <CategoryCard
            title="Crown review"
            link="/nikke/guides/guide-crown"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_crown.webp"
                alt="Crown review"
              />
            }
          />
          <CategoryCard
            title="Bay review"
            link="/nikke/guides/guide-bay"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_bay.webp"
                alt="Bay review"
              />
            }
          />
          <CategoryCard
            title="Rem review"
            link="/nikke/guides/guide-rem"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_rem.webp"
                alt="Rem review"
              />
            }
          />
          <CategoryCard
            title="Emilia review"
            link="/nikke/guides/guide-emilia"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_emi.webp"
                alt="Emilia review"
              />
            }
          />
          <CategoryCard
            title="D: Killer Wife review"
            link="/nikke/guides/guide-d-killer-wife"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dkiller.jpg"
                alt="D: Killer Wife review"
              />
            }
          />
          <CategoryCard
            title="Elegg review"
            link="/nikke/guides/guide-elegg"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_elegg.jpg"
                alt="Elegg review"
              />
            }
          />
          <CategoryCard
            title="Ade review"
            link="/nikke/guides/guide-ade"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ade.jpg"
                alt="Ade review"
              />
            }
          />
          <CategoryCard
            title="Privaty: Unkind Maid review"
            link="/nikke/guides/guide-privaty-maid"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_priv.jpg"
                alt="Privaty: Unkind Maid review"
              />
            }
          />
          <CategoryCard
            title="Moran review"
            link="/nikke/guides/guide-moran"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_moran.jpg"
                alt="Moran review"
              />
            }
          />
          <CategoryCard
            title="Leona review"
            link="/nikke/guides/guide-leona"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_leona.jpg"
                alt="Leona review"
              />
            }
          />
          <CategoryCard
            title="Scarlet: Black Shadow review"
            link="/nikke/guides/scarlet-shadow-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_scarlet.jpg"
                alt="Scarlet: Black Shadow review"
              />
            }
          />
          <CategoryCard
            title="Mica: Snow Buddy review"
            link="/nikke/guides/mica-snow-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_xmica.jpg"
                alt="Mica: Snow Buddy review"
              />
            }
          />
          <CategoryCard
            title="Ludmilla: Winter Owner review"
            link="/nikke/guides/ludmilla-winter-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_xlud.jpg"
                alt="Ludmilla: Winter Owner review"
              />
            }
          />
          <CategoryCard
            title="Tove review"
            link="/nikke/guides/tove-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tove.jpg"
                alt="Tove review"
              />
            }
          />
          <CategoryCard
            title="Red Hood review"
            link="/nikke/guides/red-hood-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_red.jpg"
                alt="Red Hood review"
              />
            }
          />
          <CategoryCard
            title="Quiry review"
            link="/nikke/guides/quiry-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_quiry.jpg"
                alt="Quiry review"
              />
            }
          />
          <CategoryCard
            title="Tia review"
            link="/nikke/guides/tia-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tia.jpg"
                alt="Tia review"
              />
            }
          />
          <CategoryCard
            title="Naga review"
            link="/nikke/guides/naga-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_naga.jpg"
                alt="Naga review"
              />
            }
          />
          <CategoryCard
            title="Marciana review"
            link="/nikke/guides/marciana-guide"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_marc.jpg"
                alt="Marciana review"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | NIKKE | Prydwen Institute"
    description="Guides for Goddess of Victory: NIKKE gacha game."
    game="nikke"
  />
);
